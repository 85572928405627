/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.monotype.com/
*/


/* resolve is part of postcss-assets */

@font-face {
  font-family: "Spezia SemiMono Medium";
  src: url("fonts/spezia/SpeziaSemiMonoWeb-Medium.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Spezia Medium";
  src: url("fonts/spezia/SpeziaWeb-Medium.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Spezia Bold";
  src: url("fonts/spezia/SpeziaWeb-Bold.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: "Spezia Regular";
  src: url("fonts/spezia/SpeziaWeb-Regular.woff") format('woff');
  font-display: swap;
}

  