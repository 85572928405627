@import "../../mixins.css";

.component {
  svg {
    width: auto;
    height: 50vh;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 10;
}

.titleText {
  @mixin text_white;
  @mixin bodyTitleText;
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
  z-index: 100;
}

.messageText {
  @mixin text_white;
  @mixin bodyText;
  position: absolute;
  bottom: 35%;
  width: 100%;
  text-align: center;
  z-index: 100;
}